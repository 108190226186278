canvas{
    width: 100%;
    max-width: 100%;
}
a {
    transition: color .2s, background-color .2s, border .2s, box-shadow .2s;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
    font-weight: 700;
    font-family: $base-font-family;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p{
    &:where(:last-child){
        margin-bottom: 0;
    }
    .is-theme &,.is-dark &{
        color: $white;
    }
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
img{
    max-width: 100%;
}
