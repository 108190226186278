.termsconditions {
  width: 100vw;
  background: #fff;
  /* height: 100vh !important; */
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.termsconditions .header {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.text {
  width: 90%;
  margin: 0 auto;
  padding: 10px;
  color: #000;
}

.flexbox-container {
  height: max-content !important;
  min-height: 100vh;
  overflow: auto !important;
}
