.code{
    &-toolbar{
        border-top: 1px solid $border-light;
    }
    &-preview{
        /* width */
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 6px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #dddddd; 
            border-radius: 6px;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #bdbcbc; 
        }
    }
}
.border-utils [class*="border"] {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: 0.25rem;
    background-color: #fbfbfb;
}
.rounded-utils [class*="rounded"]{
    display: inline-block;
    width: 75px;
    height: 75px;
    margin: 0.25rem;
}
.rounded-utils .rounded-pill{
    width: 120px;
}
.border-utils-0 [class*="border"]{
    border: 1px solid #888;
}
.card .code-preview{
    border-radius: $nk-border-radius;
    margin-bottom: 0 !important;
    max-height: 100px;
    padding-top: 0;
    overflow-x: hidden;
}

//Icon
.preview{
    &-icon{
        &-list{
            display: flex;
            flex-wrap: wrap;
            margin: -.75rem;
        }
        &-item{
            flex: 1 0 150px;
            padding: .75rem;
            max-width: 50%;
            height: 100%;
        }
        &-box{
            position: relative;
            padding: 25px 20px;
            text-align: center;
            border: $card-border-width solid $card-border-color;
            box-shadow: $card-box-shadow;
            .btn-clipboard{
                position: absolute;
                top: 0;
                right: 0;
                opacity: 0;
                z-index: 1;
                &:focus{
                    border-color:transparent;
                    box-shadow: none;
                }
            }
            &:hover{
                .btn-clipboard{
                    opacity: 1;
                }
            }
            
        }
        &-wrap{
            padding-bottom: 5px;
            .ni{
                font-size: 30px;
            }
            .preview-icon-svg &{
                margin-bottom: .5rem;
                svg{
                    width: 90px;
                }
            }
        }
        &-name{
            color: $base-light;
            font-size: 13px;
        }
    }
}
