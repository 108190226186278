.nk-footer{
    background-color: $white;
    margin-top: auto;
    border-top: 1px solid $border-light;
    padding: $footer-gap-y $footer-gap-x;
    &-wrap{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }
    &-copyright{
        font-size: 0.875rem;
        padding-top: 0.5rem;
    }
}


@include media-breakpoint-up(sm){
    .nk-footer{
        &-wrap{
            flex-wrap: nowrap;
            flex-direction: row;
        }
        &-copyright{
            padding-top: 0;
        }
    }
}