// Variables
$reply-body-gap-x : $ibx-item-gutter-x;
$reply-content-offset : 3.175rem;

// Reply of Messages
//////////////////////////////
.nk-reply {
    height: 100%;
    max-height: 100%;
    overflow: auto;
    &-item{
        padding: 1rem $reply-body-gap-x;
    }
    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        .user-name{
            margin-left: 1rem;
            font-size: .875rem;
            span{
                color: $base-light;
                font-weight: 400;
            }
        }
        .date-time{
            color: $base-light;
            font-size: .75rem;
        }
    }
    &-entry{
        &.note{
            background: $lighter;
            padding: 1.25rem;
            border-radius: $border-radius;
        }
        p{
            font-size: 0.938rem;
        }
    }
    &-from{
        margin-top: 1.25rem;
        padding: .375rem .75rem;
        background: $lighter;
        border-radius: $border-radius;
        display: inline-block;
        font-size: 0.813rem;
    }
    &-meta{
        overflow: hidden;
        text-align: center;
        margin:0 $reply-body-gap-x;
        padding: 0 2rem;
        &-info {
            position: relative;
            display: inline-block;
            padding: 0 .75rem;
            color: $base-light;
            font-size: 0.813rem;
            line-height: 1.4;
            span,strong, .who{
                color:$base-text;
            }
            .whom {
                color: $accent-color;
            }
            &:before,&:after{
                position: absolute;
                height: 1px;
                background: $border-light;
                content: '';
                width: 100vw;
                top: 50%;
            }
            &:before{
                right: 100%;
            }
            &:after{
                left: 100%;
            }
        }
    }
    &-form {
        border-top: 1px solid $border-light;
        &-dropdown{
            margin-left: -0.5rem;
            margin-right: 0.75rem;
        }
        &-header{
            display: flex;
            align-items: center;
            padding:.5rem $reply-body-gap-x;
            border-bottom: 1px solid $border-light;
        }
        &-title{
            display: flex;
            align-items: center;
            .title{
                color:$base-light;
                margin-right: .75rem;
            }
        }
        &-field{
            padding:.5rem 1.25rem;
            &:not(:last-child){
                border-bottom: 1px solid $border-light;
            }
            .form-control{
                font-size: 0.875rem;
            }
            textarea.form-control{
                min-height: 78px;
                &.ex-large{
                    min-height: 160px;
                }
            }
            & + .nk-reply-form-tools {
                border-top: 0;
            }
        }
        &-tools{
            padding: 1.25rem $reply-body-gap-x;
            border-top: 1px solid $border-light;
            display: flex;
            justify-content: space-between;
        }
        &-actions{
            display: inline-flex;
            align-items: center;
        }
        &-options{
            display: inline-flex;
        }
        &-input {
            display: flex;
            .label{
                margin:.3rem .5rem .3rem 0;
                width: 1.25rem;
                color: $base-light;
            }
            &-group{
                width: 100%;
            }
            &-cc, &-bcc{
                display: none;
                &.expanded{
                    display: flex;
                }
            }
            .toggle-opt{
                display: inline-flex;
                align-items: center;
                color: $base-color;
                &:hover{
                    color: $accent-color;
                }
            }
        }
        &-group{
            position: relative;
            padding: 1rem 0;
            flex-grow: 1;
            display: flex;
            align-items: center;
        }
        &-input-to{
            padding-right: 3.5rem;
        }
        &-nav{
            position: absolute;
            display: flex;
            align-items: center;
            align-self: flex-start;
            padding: 1.0625rem 0 0;
            right: 0;
            top: 0;
            margin: 0 -.375rem;
            li{
                a{
                    font-size: .75rem;
                    color: $base-light;
                    display: block;
                    line-height: 1.5rem;
                    padding: .25rem .375rem;
                    &:hover{
                        color: $accent-color;
                    }
                }
            }
        }
        &-editor{
            .ql{
                &-toolbar{
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    padding-left: $reply-body-gap-x - .5rem;
                    padding-right: $reply-body-gap-x - .5rem;
                }
                &-editor{
                    padding: 1rem $reply-body-gap-x;
                    &.ql-blank::before{
                        left: $reply-body-gap-x;
                        right: $reply-body-gap-x;
                    }
                }
                &-container{
                    border: 0;
                }
            }
        }
    }
    &-msg{
        &-info{
            .is-collapsed &{
                display: none;
            }
        }
        &-excerpt{
            padding-top: 0.219rem;
            font-size: .866rem;
            color: $base-light;
            display: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .is-collapsed &{
                display: block;
            }
        }
        &-meta{
            padding: .75rem;
            li{
                display: flex;
                line-height: 1.125rem;
                padding: .1875rem 0;
                .label{
                    width: 75px;
                    text-align: right;
                    color: $base-light;
                    font-size: .75rem;
                    margin-right: 0.75rem;
                }
                .info{
                    font-size: 0.813rem;
                    a{
                        color: $base-text;
                    }
                }
            }
        }
    }
    &-tools{
        display: flex;
        align-items: center;
        flex-shrink: 0;
        cursor: default;
        .date{
            font-size: 0.875rem;
            margin-right: 1rem;
            line-height: 2.25rem;
            color: $base-light;
        }
        .attach-msg {
            font-size: 1rem;
            margin-right: 0.375rem;
            color: $base-light;
        }
    }
    &-desc{
        display: flex;
        flex-grow: 1;
        .is-collapsed &{
            overflow: hidden;
        }
        .nk-reply-header & {
            align-items: center;
        }
    }
    &-info{
        margin-left: 1rem;
        max-width: calc(100% - 4.5rem);
    }
    &-header {
        &.is-collapsed{
            margin-bottom: 0;
        }
        &:not(.is-opened) {
            cursor: pointer;
        }
    }
    &-body{
        .is-collapsed + &{
            display: none;
        }
    }
    &-quick-btns {
        ul {
            display: flex;
            align-items: center;
        }
    }
    &-author{
        .date{
            display: inline;
            white-space: nowrap;
            font-size: 0.75rem;
            margin-left: 0.25rem;
        }
    }
}
.is-collapsed{
    .prev-msg,.next-msg,.more-actions, .replyto-msg, .forwardto-msg{
        display: none;
    }
}

@include media-breakpoint-up(sm){
    .nk-reply{
        &-item{
            > div:not(.nk-reply-header){
                margin-left: $reply-content-offset;
            }
        }
        &-form{
            border-radius: $border-radius;
            margin: 1.25rem $reply-body-gap-x 0 ($reply-body-gap-x + $reply-content-offset);
            border: 1px solid $border-light;
        }
    }
}