.gap{
    margin-top: calc(-1 * var(--#{$prefix}gutter-y));
    margin-right: calc(-.5 * var(--#{$prefix}gutter-x));
    margin-left: calc(-.5 * var(--#{$prefix}gutter-x));
    > * {
        padding-right: calc(var(--#{$prefix}gutter-x) * .5);
        padding-left: calc(var(--#{$prefix}gutter-x) * .5);
        margin-top: var(--#{$prefix}gutter-y);
    }
}
