$dp-background-color: $white !default;
$dp-border-color: $border-color !default;
$dp-border-radius: $border-radius !default;
$dp-border-radius-small: $border-radius-sm !default;
$dp-line-height-base: 1.5 !default;
$dp-font-size-normal: $font-size-md !default;
$dp-font-size-small: $font-size-sm !default;
$dp-font-weight-semibold: 600 !default;
$dp-font-weight-bold: $font-weight-bold !default;
$dp-dropdown-offset: 4px !default;
$dp-dropdown-shadow: $dropdown-box-shadow !default;
$dp-dropdown-z: $zindex-dropdown !default;

$dp-title-background-color: transparent !default;

$dp-cell-size-base: 2.25rem !default;
$dp-cell-focus-background-color: darken($dropdown-link-hover-bg, 2.5) !default;
// $dp-cell-prevnext-color: color('gray') !default;
$dp-cell-disabled-color: $dropdown-link-disabled-color !default;
$dp-cell-selected-background-color: $dropdown-link-active-bg !default;
$dp-cell-selected-color: $dropdown-link-active-color !default;
$dp-cell-selected-font-weight: 600 !default;
$dp-cell-today-background-color: $teal !default;
$dp-cell-today-color: $white !default;
$dp-cell-highlighted-background-color: $gray-100 !default;
$dp-range-start-end-background-color: $secondary !default;
$dp-range-start-end-color: $dp-cell-selected-color !default;
$dp-range-background-color: $gray-200 !default;
$dp-range-today-background-color: $dp-cell-today-background-color !default;
$dp-week-color: $gray-300 !default;

$dp-footer-background-color: $light !default;

$dp-input-in-edit-border-color: darken($input-focus-border-color, 5%) !default;
$dp-input-in-edit-focus-box-shadow-size: 0 0 0.25em 0.25em !default;

@import 'mixins';

@mixin dp-button {
  .btn {
    // use custom version of .btn-light
    $background: $light;
    $border: $light;
    $hover-background: darken($background, 7.5%);
    $hover-border: darken($border, 10%);
    $active-background: darken($background, 10%);
    $active-border: darken($border, 12.5%);

    border-color: $border;
    background-color: $dp-background-color;

    &:hover {
      border-color: $hover-border;
      background-color: $hover-background;
      color: color-contrast($hover-background);
    }

    &:focus {
      border-color: $hover-border;
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5);
      background-color: $hover-background;
      color: color-contrast($hover-background);
    }

    &:disabled {
      border-color: $border;
      background-color: $background;
      color: color-contrast($background);
    }

    &:not(:disabled):active {
      border-color: $active-border;
      background-color: $active-background;
      color: color-contrast($active-background);

      &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($background), $border, 15%), 0.5);
      }
    }

    .datepicker-header & {
      @include dp-header-button-common;
    }

    .datepicker-footer & {
      @include dp-footer-button-common;
    }
  }
}

@import 'datepicker';
