$nk-block-gap: 2rem;

.nk-block{
    &-head{
        margin-bottom: 2rem;
        &-between{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-sm{
            margin-bottom: 1rem;
        }
    }
    &-title{
        margin-bottom: 0.25rem;
    }
    + .nk-block{
        margin-top: 3rem;
    }
}
.nk-page{
    &-head{
        margin-bottom: 2.5rem;
    }
}
.nk{
    &-between{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}