$chat-aside-width: 100%;
$chat-aside-width-md: 300px;
$chat-aside-width-xl: 340px;
$chat-profile-width: 0px;  //was 320
$chat-aside-gutter-x: $aside-gutter-x;
$chat-body-gutter-x: $chat-aside-gutter-x;
$chat-bubble-width: 420px;
$chat-body-bg: $white;

.nk-message{
    display: flex;
    width: 100%;
    padding: .5rem $chat-body-gutter-x;
    &-wrap{
        display: flex;
        flex-direction: column-reverse;
        // height: 100%;
    }
    &.is-me{
        flex-direction: row-reverse;
    }
    &-avatar{
        margin-right: 0.75rem;
        .is-me &{
            margin-left: 0.75rem;
            margin-right: 0;
        }
    }
    &-content{
        display: flex;
        flex-direction: inherit;
        width: 100%;
        .is-you &{
            max-width: calc(100% - 3.5rem);
        }
    }
    &-actions{
        display: flex;
        align-items: center;
        opacity: 0;
        .is-you & {
            padding-left: 0.5rem;
        }
        .is-me & {
            padding-right: 0.5rem;
            flex-direction: row-reverse;
        }
    }
    &-bubble{
        position: relative;
        background: $base-50;
        border-radius: 1rem;
        padding: .75rem 1rem .376rem;
        &.has-media{
            background: transparent;
            padding: 0;
        }
        &s{
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 420px;
            > li{
                display: flex;
                align-items: center;
                .is-me & {
                    flex-direction: row-reverse;
                }
                &:not(:first-child){
                    margin-top: .25rem;
                }
                &:hover{
                    .nk-message-actions{
                        opacity: 1;
                    }
                }
                .nk-message-bubble{
                    .is-you &{
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    .is-me &{
                        border-top-right-radius: 1rem;
                        border-bottom-right-radius: 0;
                    }
                }
                &:last-child{
                    .nk-message-bubble{
                        .is-you &{
                            border-bottom-left-radius: 1rem;
                        }
                        .is-me &{
                            // border-bottom-right-radius: 1rem;
                        }
                    }
                }
            }
        }
        .is-me &{
            background: $accent-color;
        }
    }
    &-text{
        font-size:14px;
        font-weight: 400;
        color: $base-text;
        padding-bottom: .375rem;
        .is-me &{
            color: $white;
        }
    }
    &-media{
        display: flex;
        margin: -.25rem;
        li{
            padding: .25rem;
            img{
                border-radius: .25rem;
            }
            &:first-child{
                img{
                    border-top-left-radius: 0;
                }
            }
        }
        padding-bottom: .5rem;
    }
    &-meta{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: .75rem;
        color: $base-light;
        margin: 0 -.5rem;
        .is-me &{
            color: $white;
            flex-direction: row-reverse;
        }
        li{
            position: relative;
            padding: 0 .5rem;
            &:not(:last-child){
                &:after{
                    position: absolute;
                    content: '';
                    background: currentColor;
                    height: .25rem;
                    width: .25rem;
                    border-radius: 50%;
                    right: 0;
                    top: 50%;
                    transform: translate(50%,-50%);
                    opacity: .7;
                    .is-me &{
                        left: 0;
                        transform: translate(-50%,-50%);
                    }
                }
            }
        }
    }
}

.nk-chat{
    display: flex;
    position: relative;
    background: $white;
    height: 100%;
    &-aside{
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        flex-shrink: 0;
        width: $chat-aside-width;
        height: 100%;
        background: $white;
        &-scroll{
            overflow: auto;
            max-height: 100%;
        }
    }
    &-list{
        li{
            cursor: pointer;
            padding: .875rem $chat-aside-gutter-x;
        }
        .active{
            cursor: auto;
            background:#EAF7FF ;
        }
        .media{
            &-group{
                width: 100%;
                .media + .media-text{
                    margin-left: 0.875rem;
                }
            }
            &-text{
                flex-grow: 1;
            }
        }
        .date{
            font-size: 0.75rem;
            color: $base-text;
        }
        .badge{
            font-size: 0.625rem;
        }
        .title,.text{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &-body{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        justify-content: stretch;
        flex-grow: 1;
        background: $chat-body-bg;
        display: none;
        &.show-chat{
            display: flex;
            z-index: 9999;
        }
    }
    &-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .75rem $chat-body-gutter-x;
        border-bottom: 1px solid $border-light;
        &-tools{
            display: flex;
            align-items: center;
        }
    }
    &-panel{
        flex-grow: 1;
        overflow: auto;
        max-height: 100%;
        padding: 1rem 0;
    }
    &-form{
        padding:$chat-body-gutter-x $chat-body-gutter-x;
    }
    &-input{
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        padding: .25rem .675rem;
        border: 1px solid $border-light;
        &-field{
            padding: 0 .25rem;
            flex-grow: 1;
        }
        &-btns{
            position: relative;
            display: flex;
            align-items: center;
            padding-right: 0.75rem;
            margin-right: 0.75rem;
            &:after{
                position: absolute;
                content: '';
                right: 0;
                width: 1px;
                height: 50%;
                top: 50%;
                transform: translateY(-50%);
                background: $border-light;
            }
        }
    }
    &-profile{
        position: absolute;
        top: 0;
        right: 0;
        width: $chat-profile-width;
        background: $white;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        flex-shrink: 0;
        border-left: 1px solid $border-light;
        transform: translateX(100%);
        &.show-profile{
            transform: none;
        }
        &.toggle-collapsed{
            transition: transform .3s ease;
        }
    }
    &-media{
        &-list{
            display: flex;
            flex-wrap: wrap;
        }
        &-item{
            position: relative;
            border-radius: $border-radius;
            img{
                border-radius: $border-radius;
            }
            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                font-size: 1.25rem;
                height: 1.5rem;
                width: 1.5rem;
                border-radius: 50%;
                background: rgba($white,.4);
            }
        }
    }
}

@include media-breakpoint-up(md){
    .nk-chat{
        &-aside{
            width: $chat-aside-width-md;
            border-right:1px solid $border-light;
        }
        &-body{
            position: static;
            display: flex !important;
            z-index: auto !important;
        }
    }
}

@include media-breakpoint-up(xxl){
    .nk-chat{
        &-aside{
            width: $chat-aside-width-xl;
        }
    }
}

@include media-breakpoint-up(xxl){
    .nk-chat{
        &-body{
            transition: padding .3s ease;
            .profile-shown &{
                padding-right: $chat-profile-width;
            }
        }
        &-profile{
            transition: transform .3s ease;
        }
    }
 }